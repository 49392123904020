<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__participantdept">
      <el-form :inline="true" :model="dataForm" @keyup.enter.native="getDataList()">
        <el-form-item>
          <project-select v-model="dataForm.prjId" placeholder="项目选择" @change="prjChangeHandle" :defaultSelect="true" style="width: 240px;"></project-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="dataForm.name" placeholder="单位名称" clearable style="width: 220px"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button-group style="margin-top: -3px;">
            <toolbar-button role="query" @click="getDataList()"></toolbar-button>
            <toolbar-button role="add" v-if="$hasPermission('sys:participantdept:save')" @click="addOrUpdateHandle()"></toolbar-button>
            <toolbar-button role="delete" v-if="$hasPermission('sys:participantdept:delete')" @click="deleteHandle()"></toolbar-button>
          </el-button-group>
        </el-form-item>
      </el-form>
      <el-table v-loading="dataListLoading" :data="dataList" highlight-current-row border @selection-change="dataListSelectionChangeHandle" @sort-change="dataListSortChangeHandle" style="width: 100%;" :cell-style="{padding: '2px 0'}">
        <el-table-column type="selection" align="center" width="50"></el-table-column>
        <el-table-column prop="name" label="单位名称" align="left" show-overflow-tooltip sortable="custom">
          <template v-slot="{row}">
            <el-button type="text" size="small" @click="viewHandle(row.id)">{{row.name}}</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="联系电话" width="150" align="center" show-overflow-tooltip sortable="custom"></el-table-column>
        <!-- <el-table-column prop="lawPerson" label="法人代表" width="100" header-align="center" align="center" show-overflow-tooltip></el-table-column> -->
        <el-table-column prop="depositName" label="开户银行" width="150" align="center" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column prop="bankAccount" label="开户账号" width="200" align="center" show-overflow-tooltip sortable="custom"></el-table-column>
        <el-table-column :label="$t('handle')" fixed="right" align="center" width="190">
          <template slot-scope="scope">
            <table-button role="update" v-if="$hasPermission('sys:participantdept:update')" @click="addOrUpdateHandle(scope.row.id)"></table-button>
            <table-button role="delete" v-if="$hasPermission('sys:participantdept:delete')" @click="deleteHandle(scope.row.id)"></table-button>
            <table-button role="files" @click="uploadDrawerHandle(scope.row.id, [], !$hasPermission('sys:participantdept:update'))"></table-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle">
      </el-pagination>
      <!-- 弹窗, 查看 -->
      <view-page v-if="viewVisible" ref="view" @close="closeDialogHandle"></view-page>
      <!-- 弹窗, 新增 / 修改 -->
      <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList" @close="closeDialogHandle"></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module'
import AddOrUpdate from './participantdept-add-or-update'
import ViewPage from './participantdept-view'
export default {
  mixins: [mixinViewModule],
  data () {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/sys/participantDept/page',
        getDataListIsPage: true,
        deleteURL: '/sys/participantDept',
      },
      dataForm: {
        prjId: '',
        name: '',
      },
    }
  },
  watch: {
  },
  components: {
    AddOrUpdate,
    ViewPage
  },
  methods: {
    // 项目切换事件
    prjChangeHandle () {
      this.getDataList()
    },
    addOrUpdateHandle (id) {
      this.addOrUpdateVisible = true
      this.$nextTick(() => {
        this.$refs.addOrUpdate.dataForm.id = id
        if (!id) {
          this.$refs.addOrUpdate.dataForm.prjId = this.dataForm.prjId
        }
        this.$refs.addOrUpdate.init()
      })
    },
  }
}
</script>