<template>
  <div>
    <baidu-map :style="mapStyle" :center="center" :zoom="zoom" :scroll-wheel-zoom="true" :map-type="mapType" @ready="mapReadyHandler">
      <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
      <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
      <!-- <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT" :offset="{width: 10, height: 60}"></bm-city-list> -->
      <!-- <bm-marker :position="makerPosition"></bm-marker> -->
      <bm-polygon :path="polygonPath" stroke-color="blue" :stroke-opacity="0.5" :stroke-weight="2" :editing="true" @lineupdate="updatePolygonPath"/>
    </baidu-map>
    <vxe-modal ref="videoModal" title="倾斜摄影" :zIndex="1500" v-model="videoModalVisible" destroy-on-close width="1030" height="580" :position="position" :lockView="false">
      <presche-video></presche-video>
    </vxe-modal>
  </div>
</template>

<script>
import PrescheVideo from './presche-video'
export default {
  components: {
    PrescheVideo
  },
  data() {
    return {
      center: { lng: 121.241914, lat: 41.371511 },
      zoom: 5,
      // 地图类型:
      // BMAP_NORMAL_MAP，此地图类型展示普通街道视图
      // BMAP_PERSPECTIVE_MAP，此地图类型展示透视图像视图
      // BMAP_SATELLITE_MAP，此地图类型展示卫星视图
      // BMAP_HYBRID_MAP，此地图类型展示卫星和路网的混合视图
      mapType: 'BMAP_HYBRID_MAP',
      // makerPosition: { lng: 0, lat: 0 },
      mapStyle: {
        width: '100%',
        height: '500px',
      },
      // 百度地图核心类
      BMap: null,
      // 百度地图实例
      map: null,
      // 地图是否加载完成
      mapReady: false,
      polygonPath: [
        {lng: 121.241714, lat: 41.371441},
        {lng: 121.241184, lat: 41.371441},
        {lng: 121.241184, lat: 41.371611},
        {lng: 121.241714, lat: 41.371611}
      ],
      position: {
        top: 200,
        left: `calc(40%)`
      },
      videoModalVisible: false,
    }
  },
  mounted() {
    this.$nextTick(() => {
      //this.tableHeight = window.innerHeight - 120;
    })
  },
  methods: {
    mapReadyHandler ({ BMap, map }) {
      this.BMap = BMap
      this.map = map
      this.mapReady = true
      this.mapHandler()
    },
    // 项目地图-marker的label样式
    getMarkerLabelStyle (content) {
      //左偏移  右偏移
      var offsetSize = new this.BMap.Size(-120, -30)
      var labelStyle = {
        color: "#fff",
        backgroundColor: "rgba(255, 140, 0, 0.85)", // 改为暖橙色
        borderRadius: "15px",
        padding: "8px 15px",
        fontSize: "16px",
        lineHeight: "24px",
        border: "2px solid rgba(255,255,255,0.8)",
        boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
        transform: "scale(0.9)",
        transition: "all 0.3s ease",
        cursor: "pointer",
        overflow: "hidden",
        position: "relative",
      }

      var label = new this.BMap.Label(content, {
        offset: offsetSize,
        enableMassClear: true
      })
      label.setStyle(labelStyle)

      // 添加鼠标悬停效果
      label.addEventListener("mouseover", function(){
        this.setStyle({
          backgroundColor: "rgba(255, 165, 0, 0.9)", // 改为较亮的橙色
          transform: "scale(1.1)",
          boxShadow: "0 4px 12px rgba(0,0,0,0.4)",
          "&:after": {
            borderTopColor: "rgba(255, 165, 0, 0.9)", // 改为较亮的橙色
            filter: "drop-shadow(0 4px 4px rgba(0,0,0,0.3))"
          }
        })
      })

      label.addEventListener("mouseout", function(){
        this.setStyle(labelStyle)
      })

      return label
    },
    mapHandler() {
      if (this.BMap) {
        let pointList = [] // 所有的点集合
        // 设置marker标记的图标
        let icon = new this.BMap.Icon(require("@/assets/img/location.gif"), new this.BMap.Size(48, 48))
        icon.setImageSize(new this.BMap.Size(48,48))
        
        // demo
        let _point = new this.BMap.Point(121.241914, 41.371511)
        pointList.push(_point)
        let _marker = new this.BMap.Marker(_point, { icon: icon })
        //_marker.setLabel(this.getMarkerLabelStyle('秦沈高速'))

        _marker.addEventListener('click', (e) => {
          // alert('打开视频')
          this.videoModalVisible = true
        })

        this.map.addOverlay(_marker)

        // 计算所有点的中心点，将地图聚焦到中心点，并设置地图缩放级别
        let centerPoint = this.map.getViewport(pointList)
        this.map.centerAndZoom(centerPoint.center, centerPoint.zoom)
      }
    },
    updatePolygonPath (e) {
      this.polygonPath = e.target.getPath()
      // 打印所有点的坐标
      console.log('多边形各个点的坐标:')
      this.polygonPath.forEach((point, index) => {
        console.log(`点${index + 1}: 经度=${point.lng}, 纬度=${point.lat}`)
      })
    },
  }
}
</script>
<style lang="scss" scoped>

</style>