<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-__presche">
      <my-container :leftWidth="300">
        <template v-slot:left>
          <el-form inline size="mini">
            <el-form-item>
              <pre-project-select width="280px" v-model="dataForm.prePrjId" placeholder="请选择项目" :defaultSelect="true" @change="preprojectSelectHandle"></pre-project-select>
            </el-form-item>
          </el-form>
          <div class="tree-container">
            <vxe-table
                resizable
                height="auto"
                ref="vxeTree"
                show-overflow
                :loading="treeLoading"
                :tree-config="treeConfig"
                :row-config="areaTreeRowConfig"
                :data="treeData"
                @cell-click="treeCellClick">
              <vxe-column tree-node field="name" title="区域" width="500">
                <template #default="{ row }">
                  <template v-if="row.isLeaf">
                    <i class="vxe-icon-file-txt" style="margin-right: 5px"></i>
                  </template>
                  <span>{{ row.name }}</span>
                </template>
              </vxe-column>
            </vxe-table>
          </div>
        </template>
        <template v-slot:right>
          <vxe-table
              border
              resizable
              show-overflow
              ref="scheTable"
              :data="dataList"
              :loading="dataListLoading"
              :height="tableHeight"
              :cell-class-name="cellClassName"
              :row-config="scheRowConfig"
              :edit-config="editConfig"
              @edit-closed="editClosedEvent">
            <vxe-column type="seq" width="40" fixed="left" align="center">
              <template v-slot:header>
                <i v-if="canAdd()" class="el-icon-circle-plus-outline" style="color:green;cursor:pointer;font-size: 20px" @click="autoAddNewRow"></i>
              </template>
            </vxe-column>
            <vxe-column field="preAreaName" :visible="!canAdd()" title="区域" width="140"></vxe-column>
            <vxe-column field="typeId" title="类型" width="160" align="center" :edit-render="{name: '$select', props: {options: typeList}, events: {change: selectChange}}">
              <template #header>
                <span v-if="canAdd()" style="color: red;">*</span>
                <span>类型</span>
              </template>
              <template #default="{ row }">
                <span>{{ getTypeName(row.typeId) }}</span>
              </template>
            </vxe-column>
            <vxe-column field="name" title="名称" min-width="200" :edit-render="{name: '$input', events: {change: textChange}}"></vxe-column>
            <vxe-column field="unitId" title="单位" width="100" align="center" :edit-render="{name: '$select', props: {options: unitList}, events: {change: selectChange}}">
              <template #default="{ row }">
                <span>{{ getUnitName(row.unitId) }}</span>
              </template>
            </vxe-column>
            <vxe-column field="num" title="数量" width="140" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: textChange}}" :formatter="numFormatter"></vxe-column>
            <vxe-column field="percentage" title="完成百分比(%)" width="120" align="right" :edit-render="{name: '$input', props: {type: 'number'}, events: {change: textChange}}" :formatter="formatterPercentage"></vxe-column>
            <vxe-column field="money" title="金额(元)" width="140" align="right" :edit-render="{name: '$input', props: {type: 'number', controls: false}, events: {change: textChange}}" :formatter="numFormatter"></vxe-column>
            <vxe-column title="操作" width="80" align="center" fixed="right">
              <template #default="{row}">
                <table-button role="delete" v-if="$hasPermission('opm:presche:delete')&&row.id" @click="deleteHandle(row.id)"></table-button>
              </template>
            </vxe-column>
          </vxe-table>
          <presche-map />
        </template>
      </my-container>
    </div>
  </el-card>
</template>

<script>
import XEUtils from "xe-utils"
import PrescheMap from "./presche-map"

export default {
  data() {
    return {
      dataList: [],
      dataListLoading: false,
      dataForm: {
        prePrjId: ''
      },
      treeLoading: false,
      treeData: [],
      treeConfig: {
        transform: true,
        rowField: 'id',
        parentField: 'pid',
        lazy: true,
        hasChild: 'hasChildren',
        iconOpen: 'vxe-icon-square-minus',
        iconClose: 'vxe-icon-square-plus',
        loadMethod: this.loadChildren
      },
      areaTreeRowConfig: {
        isCurrent: true,
        isHover: true,
        useKey: true,
        keyField: 'id',
      },
      scheRowConfig: {
        isCurrent: true,
        isHover: true,
        useKey: true
      },
      editConfig: {
        trigger: 'click',
        mode: 'cell',
        beforeEditMethod: this.beforeEditMethod
      },
      currentSelectRow: null, // 当前选中的区域节点
      tableHeight: 300,

      typeList: [
        {label: '耕地', value: 1},
        {label: '非耕地', value: 2},
        {label: '房屋拆迁', value: 11},
        {label: '企业拆迁', value: 12},
        {label: '管线及附着物', value: 13}
      ],
      unitList: [
        {label: '亩', value: 1},
        {label: '平米', value: 2}
      ],
      specialSpaceSymbol: '　', // 特殊空格符号（确保单元格下划线正确显示）
      autofocus: {autofocus: '.vxe-input--inner'} // vxetable单元格编辑自动触发焦点选中
    }
  },
  components: {
    PrescheMap
  },
  mounted() {
    this.$nextTick(() => {
      // TODO 暂时固定高度，底下放地图
      //this.tableHeight = window.innerHeight - 120;
    })
  },
  methods: {
    // 前期项目选择
    preprojectSelectHandle(data) {
      this.treeData = []
      this.dataList = []
      this.currentSelectRow = null
      this.getTreeData()
    },
    // 获取区域维护根节点列表
    getTreeData() {
      this.treeLoading = true
      this.$http.get('/opm/preArea/children', {
        params: {
          pid: 0,
          prePrjId: this.dataForm.prePrjId,
        }
      }).then(({data: res}) => {
        if (res.code !== 0) {
          this.$message.error(res.msg)
        }
        this.treeData = res.data
        if (this.treeData && this.treeData.length > 0) {
          // 默认选中一个区域根节点
          this.$nextTick(() => {
            let $table = this.$refs.vxeTree
            let rootRow = $table.getRowById(this.treeData[0].id)
            $table.setCurrentRow(rootRow).then(() => {
              this.currentSelectRow = rootRow
              this.getPreScheDataList()
              this.editConfig.enabled = this.canEdit();
            })
          })
        }
      }).catch(() => {
        this.treeData = []
        this.$message.error('加载出错')
      }).finally(() => {
        this.treeLoading = false
      })
    },
    // 加载区域子节点数据
    loadChildren({row}) {
      return this.findChildren(row.id)
    },
    findChildren(pid) {
      return new Promise((resolve, reject) => {
        this.$http.get(
            '/opm/preArea/children',
            {
              params: {
                pid: pid,
                prePrjId: this.dataForm.prePrjId,
              }
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            reject()
            return this.$message.error(res.msg)
          }
          resolve(res.data)
        }).catch(() => {
          reject()
        })
      })
    },
    // 征迁进度单元格样式
    cellClassName({row, column}) {
      if (this.canEdit()) {
        return 'cell-underline'
      }
      return null
    },
    // 左侧区域单元格的点击事件
    treeCellClick({row, triggerTreeNode}) {
      if (!triggerTreeNode) {
        // 多次点击左侧同一个区域，默认不重新加载右侧征迁进度数据
        if (!this.currentSelectRow || this.currentSelectRow.id != row.id) {
          this.currentSelectRow = row
          this.getPreScheDataList()
        }
        // 征迁进度列表是否可编辑
        this.editConfig.enabled = this.canEdit();
      }
    },
    // 查询右侧征迁进度列表
    getPreScheDataList() {
      this.dataList = []
      this.dataListLoading = true
      this.$http.get(
          '/opm/preSche/list',
          {
            params: {
              prePrjId: this.dataForm.prePrjId,
              preAreaId: this.currentSelectRow ? this.currentSelectRow.id : null,
            }
          }
      ).then(({data: res}) => {
        this.dataListLoading = false
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        this.dataList = res.data
        // 默认生成10行新记录
        this.autoAddNewRow()
      }).catch(() => {
        this.dataListLoading = false
        return this.$message.error("加载出错")
      })
    },
    // 新增征迁进度，前端自动生成10行空记录
    autoAddNewRow() {
      let num = 10
      if (this.canEdit()) {
        for (let i = 1; i <= num; i++) {
          let data = {
            id: '',
            typeId: '',
            unitId: '',
            sortNo: this.dataList.length + i
          }
          this.dataList.push(data)
        }
      }
    },
    // 征迁进度单元格编辑开始前事件
    beforeEditMethod({row}) {
      return this.canEdit()
    },
    selectChange({row}, event) {
      this.addOrUpdateRow(row)
    },
    textChange({row, column}, event) {
      // 因为vxe-table的内置$input渲染器会在一次修改中触发多次change事件，因此需要判断出原生的那一次change事件
      if (event.$event && event.$event.type == 'change') {
        this.addOrUpdateRow({...row, [column.field]: event.value})
      }
    },
    addOrUpdateRow(row) {
      let typeId = row.typeId
      if (typeId == null || typeId == '' || typeId == undefined) {
        this.$message.warning('请先选择类型！')
      } else {
        if (!row.id) {
          row.preAreaId = this.currentSelectRow.id
          row.prePrjId = this.dataForm.prePrjId
        }
        this.$http[!row.id ? 'post' : 'put']('/opm/preSche/', row).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          if (!row.id) {
            row.id = res.data
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 1000,
          })
        }).catch(() => {
          return this.$message.error("保存出错")
        })
      }
    },
    // 征迁进度单元格数值变化事件
    changeEdit(row) {
      row.changeEdit = true
    },
    // 征迁进度单元格结束编辑事件
    editClosedEvent({row, column}) {
      return
      const field = column.property
      let fieldValue = row[field]
      // 填写类型时,如果该行数据的id值为空，则新增数据,反之则修改。
      let data = {...this.dataForm, id: row.id}
      if (!this.currentSelectRow) {
        this.$message.warning('请先选择区域！')
        row[field] = null
        row.changeEdit = false
      } else {
        data.preAreaId = this.currentSelectRow.id
        let typeId = row.typeId
        if (!typeId) {
          if (row.changeEdit) {
            this.$message.warning('请先选择类型！')
            row[field] = null
            row.changeEdit = false
          }
        } else {
          if (row.changeEdit) {
            row.changeEdit = false
            data[field] = fieldValue
            if (!row.id && row.sortNo) {
              data.sortNo = row.sortNo
            }
            this.$http[!row.id ? 'post' : 'put']('/opm/preSche/', data).then(({data: res}) => {
              if (res.code !== 0) {
                return this.$message.error(res.msg);
              }
              this.$message({
                message: this.$t('prompt.success'),
                type: 'success',
                duration: 1000,
                onClose: () => {
                  if (!row.id) {
                    row.id = res.data
                  }
                }
              })
            }).catch(() => {
              return this.$message.error("出错了")
            })
          }
        }
      }
    },
    // 删除
    deleteHandle(id) {
      this.$confirm(this.$t('prompt.info', {'handle': this.$t('delete')}), this.$t('prompt.title'), {
        confirmButtonText: this.$t('confirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.$http.delete(
            `/opm/preSche`,
            {
              'data': [id]
            }
        ).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 1500,
            onClose: () => {
              this.getPreScheDataList()
            }
          })
        }).catch(() => {
          this.$message.error("删除出错!")
        })
      }).catch()
    },
    canEdit() {
      // 当前选择的是区域子节点
      let isLeaf = this.currentSelectRow && this.currentSelectRow.isLeaf
      let isPermissio = (this.$hasPermission('opm:presche:save') || this.$hasPermission('opm:presche:update'))
      return isLeaf && isPermissio
    },
    canAdd() {
      // 只有选中区域的叶子节点才能新增征迁进度
      let isLeaf = this.currentSelectRow && this.currentSelectRow.isLeaf
      let isPermissio = this.$hasPermission('opm:presche:save')
      return isLeaf && isPermissio
    },
    // 获取拆迁类型名称（1：耕地，2：非耕地，11：房屋拆迁，12：企业拆迁，13：管线及附着物）
    getTypeName(value) {
      if (value == 1) {
        return '耕地'
      } else if (value == 2) {
        return '非耕地'
      } else if (value == 11) {
        return '房屋拆迁'
      } else if (value == 12) {
        return '企业拆迁'
      } else if (value == 13) {
        return '管线及附着物'
      }
      return this.specialSpaceSymbol
    },
    // 获取单位名称 （1：亩，2：平米）
    getUnitName(value) {
      if (value == 1) {
        return '亩'
      } else if (value == 2) {
        return '平米'
      }
      return this.specialSpaceSymbol
    },
    // 格式化完成百分比
    formatterPercentage({cellValue}) {
      return cellValue ? cellValue + '%' : this.specialSpaceSymbol
    },
    // 数字格式化
    numFormatter({cellValue}) {
      return XEUtils.commafy(cellValue, {digits: 2})
    },
  }
}
</script>
<style lang="scss" scoped>
.tree-container {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 150px);
}
</style>