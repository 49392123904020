<template>
  <div>
    <!-- 行 -->
    <el-row :gutter="1">      
      <el-col :span="12">
        <el-card>
          <!-- <div slot="header" class="header-default">
            <i class="el-icon-receiving"></i><span> 投资统计分析</span>
          </div> -->
          <project-select :showShortName="false" v-model="selectedPrjId" placeholder="项目选择" :defaultSelect="true" style="width: 100%; height: 30px" @change="changeProjectHandle"></project-select>
          <el-scrollbar v-show="showOverview">
            <el-row>
              <el-col :span="8">
                <div class="color-box-1" style="background: linear-gradient(200deg, #B721FF, #21D4FD);">
                  <p>0#台账合同：</p>
                  <p style="font-weight: bold;">{{projectOverview.boqAmount}} 万</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="color-box-1" style="background: linear-gradient(200deg, #B721FF, #21D4FD);">
                  <p>累计变更：</p>
                  <p style="font-weight: bold;">{{projectOverview.changeAmount}} 万</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="color-box-1" style="background: linear-gradient(200deg, #B721FF, #21D4FD);">
                  <p>本期完成：</p>
                  <p style="font-weight: bold;">{{projectOverview.currentMeasAmount}} 万</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="color-box-1" style="background: linear-gradient(200deg, #B721FF, #21D4FD);">
                  <p>截止本期：</p>
                  <p style="font-weight: bold;">{{projectOverview.measAmount}} 万</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="color-box-1" style="background: linear-gradient(200deg, #B721FF, #21D4FD);">
                  <p>截止上期：</p>
                  <p style="font-weight: bold;">{{projectOverview.lastMeasAmount}} 万</p>
                </div>
              </el-col>
              <el-col :span="8">
                <div class="color-box-1" style="background: linear-gradient(200deg, #B721FF, #21D4FD);">
                  <!-- 查询上期完成的金额 -->
                  <p>本期应付：</p>
                  <p style="font-weight: bold;">{{projectOverview.shouldPayAmount}} 万</p>
                </div>
              </el-col>
            </el-row>
          </el-scrollbar>
        </el-card>
      </el-col>

      <el-col :span="12">
        <el-row :gutter="1">
          <el-col :span="24">
            <el-card>
              <div slot="header">
                <i class="el-icon-s-comment"></i><span> 项目总览</span>
              </div>
              <el-scrollbar style="height: 135px;" v-show="showOverview">
                <el-row>
                  <el-col :span="6">
                    <el-card shadow="hover" class="color-box-2" style="background: linear-gradient(200deg, #B721FF, #21D4FD);">
                      <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: radial-gradient(circle at 10% 20%, rgba(255,255,255,0.03) 0%, transparent 50%);"></div>
                      <p style="font-weight: bold; margin: 0; padding: 0; color: #fff; font-size: 14px; height: 54px">
                        <span v-for="(txt, index) in zjxm.mx" :key="index">
                          {{ txt }}
                        </span>
                        &nbsp;共{{ zjxm.count }}个标段
                      </p>
                      <div id="zjxmChart" v-echart-resize :style="{width: '100%', height: '70px'}"/>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card shadow="hover" class="color-box-2" style="background: linear-gradient(200deg, #B721FF, #21D4FD);">
                      <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: radial-gradient(circle at 90% 80%, rgba(255,255,255,0.03) 0%, transparent 50%);"></div>
                      <p style="font-weight: bold; margin: 0; padding: 0; color: #fff; font-size: 14px;">
                        投资总额 {{ tzze.total }}亿
                      </p>
                      <ul style="list-style: none; margin: 2px 0; padding: 0; height: 38px; color: rgba(255,255,255,0.9); font-size: 12px;">
                        <li>累计完成 {{ tzze.finished }}亿</li>
                        <li>占总投资 {{ tzze.percentage }}</li>
                      </ul>
                      <div id="tzzeChart" v-echart-resize :style="{width: '100%', height: '70px'}"/>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card shadow="hover" class="color-box-2" style="background: linear-gradient(200deg, #B721FF, #21D4FD);">
                      <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: radial-gradient(circle at 90% 10%, rgba(255,255,255,0.03) 0%, transparent 50%);"></div>
                      <p style="font-weight: bold; margin: 0; padding: 0; color: #fff; font-size: 14px;">
                        年计划 {{ ndjh.plan }}亿
                      </p>
                      <ul style="list-style: none; margin: 2px 0; padding: 0; height: 38px; color: rgba(255,255,255,0.9); font-size: 12px;">
                        <li>已完成 {{ ndjh.finished }}亿</li>
                        <li>完成比例 {{ ndjh.percentage }}</li>
                      </ul>
                      <div id="ndjhChart" v-echart-resize :style="{width: '100%', height: '70px'}"/>
                    </el-card>
                  </el-col>
                  <el-col :span="6">
                    <el-card shadow="hover" class="color-box-2" style="background: linear-gradient(200deg, #B721FF, #21D4FD);">
                      <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: radial-gradient(circle at 10% 80%, rgba(255,255,255,0.03) 0%, transparent 50%);"></div>
                      <div style="height: 54px">
                        <p style="font-weight: bold; margin: 0; padding: 0; color: #fff; font-size: 14px;">
                          变更总额 {{ bgze.total }}亿
                        </p>
                        <ul style="list-style: none; margin: 2px 0; padding: 0; height: 38px; color: rgba(255,255,255,0.9); font-size: 12px;">
                          <li>占总投资 {{ bgze.percentage }}</li>
                        </ul>
                      </div>
                      <div id="bgzeChart" v-echart-resize :style="{width: '100%', height: '70px'}"/>
                    </el-card>
                  </el-col>
                </el-row>
              </el-scrollbar>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- 行：地图、待办 -->
    <el-row :gutter="1">
      <el-col :span="12">
        <el-card>
          <baidu-map :style="mapStyle" :center="center" :zoom="zoom" :scroll-wheel-zoom="true" :map-type="mapType" @ready="mapReadyHandler">
            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
            <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_LEFT"></bm-map-type>
            <!-- <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT" :offset="{width: 10, height: 60}"></bm-city-list> -->
            <!-- <bm-marker :position="makerPosition"></bm-marker> -->
          </baidu-map>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div slot="header" class="header-default">
            <i class="el-icon-receiving"></i><span> 待办流程</span>
          </div>
          <el-scrollbar style="height: 265px">
            <ul v-for="o in unFinishedWfList" :key="o.id">
              <li>
                <a href="javascript:;" @click="openMyWf(o)"><i class="el-icon-edit"></i> {{ o.processTitle }}</a>
              </li>
            </ul>
          </el-scrollbar>
        </el-card>
      </el-col>
    </el-row>
    <!-- 行：章节计量、计量进度 -->
    <el-row :gutter="5" style="margin-top: 5px;">
      <el-col :span="12">
        <el-card>
          <div slot="header" class="header-default">
            <i class="el-icon-picture-outline"></i><span> 章节计量统计</span>
          </div>
          <el-row :gutter="12">
            <el-col :span="24">
              <el-card shadow="hover">
                <div>
                  <!-- <project-select v-model="chapterChart.prjId" placeholder="项目选择" :defaultSelect="true" style="width: 200px;" @change="measChapterChartHandle"></project-select> -->
                  <!-- <contract-select v-model="chapterChart.contractId" :prj-id="chapterChart.prjId" ref="contractSelect" placeholder="合同标段选择" @change="measChapterChartHandle" :defaultSelect="true" style="width: 200px;"></contract-select> -->
                </div>
                <div style="width: 70%; float: left; overflow: hidden;color:red">
                  <div id="chapterChart" v-echart-resize :style="{width: '100%', height: '260px'}"/>
                </div>
                <div style="color:red;width: 30%; float: left; overflow: hidden">
                  <div id="chapterChartTotal" v-echart-resize :style="{width: '100%', height: '260px'}"/>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div slot="header" class="header-default">
            <i class="el-icon-picture-outline"></i><span> 计量进度统计</span>
          </div>
          <el-row :gutter="12">
            <el-col :span="24">
              <el-card shadow="hover">
                <!-- <div>
                  <project-select v-model="measScheChart.prjId" placeholder="项目选择" :defaultSelect="true" style="width: 200px;" @change="measScheChartHandle"></project-select>
                </div> -->
                <div v-echart-resize id="measScheChart" :style="{width: '100%', height: '260px'}"/>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <!-- 行：形象进度统计 -->
    <el-row :gutter="5" style="margin-top: 5px;">
      <el-col :span="24">
        <el-card>
          <div slot="header" class="header-default">
            <i class="el-icon-picture-outline"></i><span> 形象进度统计</span>
          </div>
          <el-row :gutter="12">
            <el-col :span="24">
              <el-card shadow="hover">
                <!-- <div>
                  <project-select v-model="virtualScheduleChart.prjId" placeholder="项目选择" :defaultSelect="true" style="width: 200px;" @change="virtualScheduleChartHandle"></project-select>
                </div> -->
                <div style="width: 100%; float: left; overflow: hidden;color:red">
                  <div id="virtualScheduleChart" v-echart-resize :style="{width: '100%', height: '260px'}"/>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <!-- 行：产值统计 -->
    <el-row :gutter="5" style="margin-top: 5px;">
      <el-col :span="24">
        <el-card>
          <div slot="header" class="header-default">
            <i class="el-icon-picture-outline"></i><span> 产值统计</span>
          </div>
          <el-row :gutter="12">
            <el-col :span="24">
              <el-card shadow="hover">
                <div style="width: 100%; float: left; overflow: hidden;color:red">
                  <div id="outputChart" v-echart-resize :style="{width: '100%', height: '260px'}"/>
                </div>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <!-- 行：质量检查、安全检查统计 -->
    <el-row :gutter="5" style="margin-top: 5px;">
      <el-col :span="12">
        <el-card>
          <div slot="header" class="header-default">
            <i class="el-icon-picture-outline"></i><span> 质量检查统计</span>
          </div>
          <el-row :gutter="12">
            <el-col :span="24">
              <el-card shadow="hover">
                <div>
                  <!-- <project-select v-model="qutyCheckChart.prjId" placeholder="项目选择" :defaultSelect="true" style="width: 200px;" @change="qutyCheckChartHandle"></project-select> -->
                  <el-select style="width: 100px" v-model="qutyCheckChart.year" filterable @change="qutyCheckChartHandle">
                    <el-option v-for="y in qutyCheckChart.years" :label="y" :key="y" :value="y"></el-option>
                  </el-select>
                </div>
                <div v-echart-resize id="qutyCheckChart" :style="{width: '100%', height: '260px'}"/>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card>
          <div slot="header" class="header-default">
            <i class="el-icon-picture-outline"></i><span> 安全检查统计</span>
          </div>
          <el-row :gutter="12">
            <el-col :span="24">
              <el-card shadow="hover">
                <div>
                  <!-- <project-select v-model="safetyCheckChart.prjId" placeholder="项目选择" :defaultSelect="true" style="width: 200px;" @change="safetyCheckChartHandle"></project-select> -->
                  <el-select style="width: 100px" v-model="safetyCheckChart.year" filterable @change="safetyCheckChartHandle">
                    <el-option v-for="y in safetyCheckChart.years" :label="y" :key="y" :value="y"></el-option>
                  </el-select>
                </div>
                <div v-echart-resize id="safetyCheckChart" :style="{width: '100%', height: '260px'}"/>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <!-- 冗余行 -->
    <el-row :gutter="5" style="margin-top: 5px;">
      <el-col :span="12">
        <!-- <el-card>
          <div slot="header" class="header-default">
            <i class="el-icon-s-comment"></i><span> 通知公告</span>
          </div>
          <el-scrollbar style="height: 200px">
            <ul v-for="o in noticeList" :key="o.id">
              <li>
                <a href="javascript:;" @click="openMyNotice(o.id)"><i class="el-icon-edit"></i> {{ o.title }}</a>
              </li>
            </ul>
          </el-scrollbar>
        </el-card> -->
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {addDynamicRoute} from '@/router'
import { HashMap } from '@/utils'
export default {
  data () {
    return {
      // 在建项目概览
      zjxm: {
        count: '',
        mx: [],
        instance: null
      },
      projectOverview: {
          boqAmount: '',
          changeAmount: '',
          currentMeasAmount: '',
          lastMeasAmount: '',
          measAmount: '',
          shouldPayAmount: ''
      },
      // 投资总额
      tzze: {
        total: 0,// 投资总额
        finished: '',// 已完成投资额
        percentage: '',// 已完成投资比例
        instance: null
      },
      // 年度投资计划
      ndjh: {
        plan: 0,// 年计划总投资
        finished: '',// 年度完成投资额
        percentage: '',// 完成投资比例
        instance: null
      },
      // 变更总额
      bgze: {
        total: 0,
        percentage: '',
        instance: null
      },
      // 百章计量统计
      chapterChart: {
        prjId: '',
        // contractId: '',
        instanceChapter: null,
        instanceTotal: null
      },
      // 产值统计
      outputChart: {
        prjId: '',
        instance: null
      },
      // 形象进度统计
      virtualScheduleChart: {
        prjId: '',
        instance: null
      },
      measScheChart: {
        prjId: '',
        instance: null
      },
      // 质量检查问题统计
      qutyCheckChart: {
        prjId: '',
        year: new Date().getFullYear(),
        // 年份的选项要改造为动态生成
        years: this.makeYearOptions(),
        instance: null
      },
      // 安全检查问题统计
      safetyCheckChart: {
        prjId: '',
        year: new Date().getFullYear(),
        // 年份的选项要改造为动态生成
        years: this.makeYearOptions(),
        instance: null
      },
      unFinishedWfList: [], // 我的待办流程
      noticeList: [], // 通知公告
      chartLoadingText:{// 图表loading效果
        text: '统计中，请稍候...',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(3,3,8,0.1)',
        zlevel: 0,
      },
      center: { lng: 102.114129, lat: 37.150339 },
      zoom: 5,
      // 地图类型:
      // BMAP_NORMAL_MAP，此地图类型展示普通街道视图
      // BMAP_PERSPECTIVE_MAP，此地图类型展示透视图像视图
      // BMAP_SATELLITE_MAP，此地图类型展示卫星视图
      // BMAP_HYBRID_MAP，此地图类型展示卫星和路网的混合视图
      mapType: 'BMAP_HYBRID_MAP',
      // makerPosition: { lng: 0, lat: 0 },
      mapStyle: {
        width: '100%',
        height: '300px',
        'min-height': '100%'
      },
      selectedPrjId: '',
      // 百度地图核心类
      BMap: null,
      // 百度地图实例
      map: null,
      // 地图是否加载完成
      mapReady: false,
      // 项目概览，只允许业主用户查看
      showOverview: false,
    }
  },
  created () {
    this.$nextTick(() => {
      this.getUnFinishedWfList()
      this.getMyNoticeList()
      this.echartsRegisterCustomShape()
      // 章节计量统计图表
      this.chapterChart.instanceChapter = this.$echarts.init(document.getElementById('chapterChart'))
      this.chapterChart.instanceTotal = this.$echarts.init(document.getElementById('chapterChartTotal'))
      //  计量进度统计
      this.measScheChart.instance = this.$echarts.init(document.getElementById('measScheChart'))
      //  产值统计
      this.outputChart.instance = this.$echarts.init(document.getElementById('outputChart'))
      //  形象进度统计
      this.virtualScheduleChart.instance = this.$echarts.init(document.getElementById('virtualScheduleChart'))
      //  质量检查问题统计
      this.qutyCheckChart.instance = this.$echarts.init(document.getElementById('qutyCheckChart'))
      //  安全检查问题统计
      this.safetyCheckChart.instance = this.$echarts.init(document.getElementById('safetyCheckChart'))

      //  在建项目
      this.zjxm.instance = this.$echarts.init(document.getElementById('zjxmChart'))
      //  投资总额
      this.tzze.instance = this.$echarts.init(document.getElementById('tzzeChart'))
      //  年度计划
      this.ndjh.instance = this.$echarts.init(document.getElementById('ndjhChart'))
      //  变更总额
      this.bgze.instance = this.$echarts.init(document.getElementById('bgzeChart'))
    })
  },
  mounted() {
    this.$nextTick(() => {
      // 通过事件总线实现首页待办流程办理完成后自动刷新
      this.$bus.$on('refreshHomeUnFinishedWf', () => {
        this.getUnFinishedWfList()
      })
    })
  },
  beforeDestroy() {
    this.$bus.$off('refreshHomeUnFinishedWf')
  },
  methods: {
    // 2024年11月1日，首页的项目选择从各个板块中选择调整为统一选择
    changeProjectHandle() {
      if (this.$store.state.user.typeId == 1) {// 只有业主才需要查询显示项目概览和投资相关数字方块
        this.showOverview = true
        this.getOverview()
        this.getProjectOverview()
      } else {
        this.showOverview = false
      }
      
      // 产值统计
      this.outputChartHandle()

      this.mapHandler()
      this.measChapterChartHandle()
      this.measScheChartHandle()
      this.virtualScheduleChartHandle()
      this.qutyCheckChartHandle()
      this.safetyCheckChartHandle()
    },
    // 项目总览
    getOverview () {
      if (this.selectedPrjId) {
        this.$http.get(`/opm/home/overview?prjId=${this.selectedPrjId}`).then(({data: res}) => {
          if (res.code === 0) {
            // TODO 在建项目改成其他内容？
            this.zjxm.count = res.data.zjxm_count
            // 在建项目分类明细
            this.zjxm.mx = res.data.zjxm_mx
            // 各类型在建项目统计饼形图
            this.showZjxmChart(res.data.zjxm_chart)

            // 投资总额
            this.tzze.total = res.data.tzze_total
            // 已完成投资额
            this.tzze.finished = res.data.tzze_finished
            // 已完成投资比例
            this.tzze.percentage = res.data.tzze_percentage + '%'
            // 投资总额完成率仪表盘
            this.showGaugeBoard('投资完成', res.data.tzze_percentage, this.tzze.instance)

            // 年计划投资总额
            this.ndjh.plan = res.data.ndjh_plan
            // 年完成投资额
            this.ndjh.finished = res.data.ndjh_finished
            // 完成比例
            this.ndjh.percentage = res.data.ndjh_percentage + '%'
            // 年投资完成率仪表盘
            this.showGaugeBoard('投资完成', res.data.ndjh_percentage, this.ndjh.instance)

            // 变更总额
            this.bgze.total = res.data.bgze_total
            this.bgze.percentage = res.data.bgze_percentage  + '%'
            // 变更总额占投资总额占比饼形图
            this.showBgzeChart(res.data.tzze_total_wan, res.data.bgze_total_wan)
          }
        })
      }
    },
    getProjectOverview() {
      this.$http.get(`/mps/home/projectOverview/${this.selectedPrjId}`).then(({data: res}) => {
        this.projectOverview.boqAmount = res.boqAmountTotal
        this.projectOverview.changeAmount = res.changeAmountTotal
        this.projectOverview.currentMeasAmount = res.currentMeasAmountTotal
        this.projectOverview.lastMeasAmount = res.lastMeasAmountTotal
        this.projectOverview.measAmount = res.measAmountTotal
        this.projectOverview.shouldPayAmount = res.shouldPayAmountTotal
      })
    },
    // 各类型在建项目饼形图
    showZjxmChart (data) {
      let seriesData = []
      for (const key in data) {
        seriesData.push({
          name: key,
          value: data[key]
        })
      }
      var option = {
        tooltip: {
          trigger: 'item',
          position: 'top',
          formatter: function (p) {
            return p.name + ' : ' + p.value + '个'
          }
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '65%'],
            data: seriesData,
            color: this.getLinears(),
            labelLine: {
              length: 5, // 第一段线长度
              length2: 10 // 第二段线长度
            }
          },
        ],
      }
      if (this.zjxm.instance) {
        this.zjxm.instance.clear()
        this.zjxm.instance.setOption(option)
      }
    },
    // 完成率仪表盘（公用的）
    showGaugeBoard(title, percentage, instance) {
      var colorTemplate1 = [[0.2, "rgba(255,0,0,0.8)"], [0.8, "#00CCFF"], [1, "rgba(0,255,0,0.8)"]];
      var data1 = [{
        name: title,
        value: percentage,
      }]
      var option = {
        tooltip: {
          position: 'top',
          show: true,
          formatter: "{b}：{c}%",
        },
        series: [
          {
            name: "仪表盘",
            type: "gauge", // 系列类型
            radius: "80%", // 参数:number, string。 仪表盘半径,默认 75% ，可以是相对于容器高宽中较小的一项的一半的百分比，也可以是绝对的数值。
            center: ["50%", "55%"], // 仪表盘位置(圆心坐标)
            startAngle: 225, // 仪表盘起始角度,默认 225。圆心 正右手侧为0度，正上方为90度，正左手侧为180度。
            endAngle: -45, // 仪表盘结束角度,默认 -45
            clockwise: true, // 仪表盘刻度是否是顺时针增长,默认 true。
            min: 0, // 最小的数据值,默认 0 。映射到 minAngle。
            max: 100, // 最大的数据值,默认 100 。映射到 maxAngle。
            splitNumber: 10, // 仪表盘刻度的分割段数,默认 10。

            axisLine: { // 仪表盘轴线(轮廓线)相关配置
              show: true, // 是否显示仪表盘轴线(轮廓线),默认 true。
              lineStyle: { // 仪表盘轴线样式。
                color: colorTemplate1,  //仪表盘的轴线可以被分成不同颜色的多段。每段的  结束位置(范围是[0,1]) 和  颜色  可以通过一个数组来表示。默认取值：[[0.2, '#91c7ae'], [0.8, '#63869e'], [1, '#c23531']]
                opacity: 1, //图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。
                width: 30, //轴线宽度,默认 30。
                shadowBlur: 20, //(发光效果)图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果。 
                shadowColor: "#fff", //阴影颜色。支持的格式同color。
              }
            },

            splitLine: { // 分隔线样式
              show: true, // 是否显示分隔线,默认 true。
              length: 9, // 分隔线线长。支持相对半径的百分比,默认 30。
              lineStyle: { // 分隔线样式。
                color: "#eee", //线的颜色,默认 #eee。
                opacity: 1, //图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。
                width: 2, //线度,默认 2。
                type: "solid", //线的类型,默认 solid。 此外还有 dashed,dotted
                shadowBlur: 10, //(发光效果)图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果。 
                shadowColor: "#fff", //阴影颜色。支持的格式同color。
              }
            },

            axisTick: { // 刻度(线)样式
              show: true, // 是否显示刻度(线),默认 true。
              splitNumber: 5, // 分隔线之间分割的刻度数,默认 5。
              length: 3, // 刻度线长。支持相对半径的百分比,默认 8。
              lineStyle: { // 刻度线样式。   
                color: "#eee", //线的颜色,默认 #eee。
                opacity: 1, //图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。
                width: 1, //线度,默认 1。
                type: "solid", //线的类型,默认 solid。 此外还有 dashed,dotted
                shadowBlur: 10, //(发光效果)图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果。 
                shadowColor: "#fff", //阴影颜色。支持的格式同color。
              },
            },

            axisLabel: { // 刻度标签
              show: false, // 是否显示标签,默认 true。
              distance: 2, // 标签与刻度线的距离,默认 5。
              color: "#fff", // 文字的颜色,默认 #fff。
              fontSize: 12, // 文字的字体大小,默认 5。
              formatter: "{value}", // 刻度标签的内容格式器，支持字符串模板和回调函数两种形式。 示例:// 使用字符串模板，模板变量为刻度默认标签 {value},如:formatter: '{value} kg'; // 使用函数模板，函数参数分别为刻度数值,如formatter: function (value) {return value + 'km/h';}
            },

            pointer: { // 仪表盘指针
              show: true, // 是否显示指针,默认 true。
              length: "50%", // 指针长度，可以是绝对数值，也可以是相对于半径的百分比,默认 80%。
              width: 5, // 指针宽度,默认 8。
            },

            itemStyle: { // 仪表盘指针样式
              color: "auto", // 指针颜色，默认(auto)取数值所在的区间的颜色
              opacity: 1, // 图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。
              borderWidth: 0, // 描边线宽,默认 0。为 0 时无描边。
              borderType: "solid", // 柱条的描边类型，默认为实线，支持 'solid', 'dashed', 'dotted'。
              borderColor: "#000", // 图形的描边颜色,默认 "#000"。支持的颜色格式同 color，不支持回调函数。
              shadowBlur: 10, // (发光效果)图形阴影的模糊大小。该属性配合 shadowColor,shadowOffsetX, shadowOffsetY 一起设置图形的阴影效果。 
              shadowColor: "#fff", // 阴影颜色。
            },

            title: { // 仪表盘标题
              show: false, // 是否显示标题
              offsetCenter: [0, "80%"],//相对于仪表盘中心的偏移位置，数组第一项是水平方向的偏移，第二项是垂直方向的偏移。可以是绝对的数值，也可以是相对于仪表盘半径的百分比。
              color: "#fff", // 文字的颜色,默认 #333。
              fontSize: 15, // 文字的字体大小,默认 15。
            },

            detail: { // 仪表盘详情，用于显示数据。
              show: false, // 是否显示详情,默认 true。
              offsetCenter: [0, "70%"],// 相对于仪表盘中心的偏移位置，数组第一项是水平方向的偏移，第二项是垂直方向的偏移。可以是绝对的数值，也可以是相对于仪表盘半径的百分比。
              color: "#fff", // 文字的颜色,默认 auto。
              fontSize: 15, // 文字的字体大小,默认 15。
              formatter: "{value}%",  // 格式化函数或者字符串
            },
            data: data1,
          }
        ]
      }
      if (instance) {
        instance.clear()
        instance.setOption(option)
      }
    },
    // 变更总额占投资总额饼形图
    showBgzeChart (tzze, bgze) {
      let seriesData = [{
        name: '投资总额',
        value: tzze
      }, {
        name: '变更总额',
        value: bgze
      }]
      var option = {
        tooltip: {
          position: 'top',
          trigger: 'item',
          formatter: "{b}：{c}万",
          // formatter: "{a} <br/>{b}: {c}万 ({d}%)"
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '65%'],
            data: seriesData,
            color: this.getLinears(),
            labelLine: {
              length: 5, // 第一段线长度
              length2: 10 // 第二段线长度
            }
          },
        ],
      }
      if (this.bgze.instance) {
        this.bgze.instance.clear()
        this.bgze.instance.setOption(option)
      }
    },
    // 加载我的待办流程
    getUnFinishedWfList () {
      this.unFinishedWfList = []
      this.$http.get(`/flowable/process/todo/page?page=1&limit=10`)
        .then(({data: res}) => {
          if (res.code === 0) {
            this.unFinishedWfList = res.data.list
          }
        }).catch()
    },
    // 加载我未阅读的通知公告
    getMyNoticeList () {
      this.noticeList = []
      this.$http.get(`/sys/notice/mynotice/page`,{
        params: {
          page: 1,
          limit: 10,
          readStatus: 0 // 只查未阅读的
        }
      }).then(({data: res}) => {
        if (res.code === 0) {
          this.noticeList = res.data.list
        }
      }).catch()
    },
    // 打开我的一条待办详情
    openMyWf (o) {
      this.$http.get(`/flowable/process/bizRoute/${o.menuId}`).then(({ data: res }) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
        if (!res.data || !res.data.bizRoute) {
          return this.$message.error(this.$t('process.routeError'))
        }
        let param = {
          ...o,
          ...res.data
        }
        let rejectProcessState = 4 // 流程被驳回状态
        // 区分正常办理待办流程和起草人办理被驳回的流程
        let processShowType = param.businessStatus == rejectProcessState ? 'draftTaskHandle' : 'taskHandle'
        let routeParams = {
          routeName: `flowable-todo__handle_${param.taskId}`,
          menuId: `${param.menuId}`,
          title: `待办流程 - ${param.processTitle}`,
          // 流程相关的业务表单详情页面有两套路由页面，一个是用于驳回起草人使用，一个是用于正常的流程审批
          path: param.businessStatus == rejectProcessState ? param.bizRouteEdit : param.bizRoute,
          params: {
            taskId: param.taskId,
            processInstanceId: param.processInstanceId,
            businessStatus: param.businessStatus,
            processTitle: param.processTitle,
            processShowType: processShowType,
            businessKey: param.businessKey,
            delegationState: param.delegationState, // 用于标识出当前任务是否为委派任务
          }
        }
        addDynamicRoute(routeParams, this.$router)
      })
    },
    // 查看我的通知公告
    openMyNotice (id) {
      // 路由参数
      let routeParams = {
        routeName: `sys-notice-user__${id}`,
        title: '通知公告',
        path: 'sys/notice-user-view',
        params: {
          id: id
        }
      }
      this.updateReadStatus(id)
      // 动态路由
      addDynamicRoute(routeParams,this.$router)
    },
    // 查看我的通知公告时，将状态修改为“已阅读”
    updateReadStatus (noticeId) {
      this.$http['put']('/sys/notice/mynotice/read/' + noticeId).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message.error(res.msg)
        }
      }).catch()
    },
    openMenuRoute (name) {
      // 路由参数
      let routeParams = {
        routeName: name,
        path: 'ur2/reports/mps-project-report'
      }
      // 动态路由
      addDynamicRoute(routeParams,this.$router)
    },
    // 章节计量统计
    measChapterChartHandle () {
      let chartData = {
        boqChapters: [],
        boqAmounts: [],
        latestMeasAmount: []
      }
      this.chapterChart.instanceChapter.showLoading(this.chartLoadingText);
      this.chapterChart.instanceTotal.showLoading(this.chartLoadingText);
      this.$http.get(`/mps/home/chapterMeasCount/${this.selectedPrjId}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message({
            message: res.msg,
            type: 'error',
            duration: 3000,
          })
        }
        chartData = res.data
        this.setChapterChartOption(chartData)
        this.chapterChart.instanceChapter.hideLoading()
        this.chapterChart.instanceTotal.hideLoading()
      })
    },
    // 章节计量统计
    setChapterChartOption (chartData) {
      let option = {
        tooltip: {
          trigger: 'axis',
          formatter (params) {
            let relVal = params[0].name;
            for (let i = 0,l = params.length; i < l; i++) {
              relVal += `<br/>${params[i].marker}${params[i].seriesName} : ${params[i].value.toLocaleString()} ${i < l - 1 ? '万' : ''}`;
            }
            return relVal;
          },
          backgroundColor: 'rgba(50,50,50,0.9)',
          borderColor: '#666',
          borderWidth: 1,
          textStyle: {
            color: '#fff'
          }
        },
        legend: {
          data: ['合同金额', '计量完成', '完成率'],
          textStyle: {
            color: '#333'
          }
        },
        grid:{
          x: 20,
          y: 40,
          x2: 20,
          y2: 10,
          containLabel: true
        },
        xAxis: {
          data: chartData.boqChapters,
          axisTick:{
            show: false,
          },
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          axisLabel: {
            color: '#666'
          }
        },
        yAxis: [
          {
            splitLine: {show: false},
            axisLine: {
              show: true,
              lineStyle: {
                color: '#999'
              }
            },
            axisLabel:{
              formatter:'{value} ',
              color: '#666'
            },
            axisTick:{
              show: true,
            },
          },
          {
            splitLine: {show: false},
            axisLine: {
              show: true,
              lineStyle: {
                color: '#999'
              }
            },
            axisLabel:{
              formatter:'{value} ',
              color: '#666'
            },
            axisTick:{
              show: true,
            },
          }
        ],
        series: [
          {
            name: '计量完成',
            type: 'bar',
            barWidth: 15,
            itemStyle: {
              barBorderRadius: 5,
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 0, color: '#4facfe'},
                    {offset: 0.5, color: '#45a6f7'},
                    {offset: 1, color: '#00f2fe'}
                  ]
              )
            },
            data: chartData.totalMeasAmounts,
          },
          {
            name: '合同金额',
            type: 'bar',
            barGap: '-100%', 
            barWidth: 15,
            itemStyle: {
              barBorderRadius: 5,
              color: new this.$echarts.graphic.LinearGradient(
                  0, 0, 0, 1,
                  [
                    {offset: 0, color: 'rgba(79,172,254,0.5)'}, 
                    {offset: 1, color: 'rgba(0,242,254,0.3)'}
                  ]
              )
            },
            z: -12,
            data: chartData.boqAmounts
          },
          {
            name: '完成率',
            type: 'line',
            smooth: true,
            showAllSymbol: true,
            symbol: 'circle',
            symbolSize: 8,
            yAxisIndex: 1,
            itemStyle: {
              normal: {
                color:'#ff6b81',
                borderColor: '#fff',
                borderWidth: 2
              }
            },
            lineStyle: {
              color: '#ff6b81',
              width: 2
            },
            data: chartData.percentages
          },
        ]
      }
      if (this.chapterChart.instanceChapter) {
        this.chapterChart.instanceChapter.clear()
        this.chapterChart.instanceChapter.setOption(option)
      }
      let boqAmount = 1 // 合同额
      let totalMeasAmount = 0 // 计量金额
      if (chartData.boqAmounts || chartData.boqAmounts.length > 0) {
        for (let i = 0; i < chartData.boqAmounts.length; i++) {
          boqAmount = boqAmount + chartData.boqAmounts[i]
        }
      }
      if (chartData.totalMeasAmounts || chartData.totalMeasAmounts.length > 0) {
        for (let i = 0; i < chartData.totalMeasAmounts.length; i++) {
          totalMeasAmount = totalMeasAmount + chartData.totalMeasAmounts[i]
        }
      }
      var option2 = {
        backgroundColor: 'transparent',
        tooltip: {
          trigger: 'item',
          formatter: function (p) {
            let value = p.value
            if (value > 999) {
              let parts = value.toString().split('.')
              parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g,',')
              value = parts.join('.')
            }
            return `<div style="padding: 8px">
              ${value}万
            </div>`
          },
          backgroundColor: 'rgba(0,0,0,0.7)',
          borderColor: '#00ffff',
          borderWidth: 1,
          textStyle: {
            color: '#fff'
          }
        },
        color: [
          new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {offset: 0, color: '#00feff'},
            {offset: 1, color: '#027eff'}
          ]),
          new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
            {offset: 0, color: '#7d7d7d'},
            {offset: 1, color: '#D19A66'}
          ])
        ],
        legend: {
          orient: 'vertical',
          left: 'left',
          textStyle: {
            color: '#666'
          },
          data: ['已完成','未完成']
        },
        series: [
          {
            name: '计量',
            type: 'pie',
            radius: ['30%','50%'],
            center: ['50%', '50%'],
            roseType: 'radius',
            data: [
              {
                value: totalMeasAmount,
                name: '已完成',
                itemStyle: {
                  shadowBlur: 10,
                  shadowColor: 'rgba(0,0,0,0.3)'
                }
              },
              {
                value: boqAmount - totalMeasAmount < 0 ? 0 : boqAmount - totalMeasAmount,
                name: '未完成',
                itemStyle: {
                  shadowBlur: 10,
                  shadowColor: 'rgba(0,0,0,0.3)'
                }
              },
            ],
            label: {
              show: true,
              position: 'outside',
              formatter: '{b}\n{d}%',
              fontSize: 14,
              color: '#666',
              fontWeight: 'bold'
            },
            labelLine: {
              length: 20,
              length2: 10,
              lineStyle: {
                width: 1,
                type: 'solid'
              }
            },
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 20,
                shadowColor: 'rgba(0,0,0,0.5)'
              }
            }
          }
        ]
      }
      if (this.chapterChart.instanceTotal) {
        this.chapterChart.instanceTotal.clear()
        this.chapterChart.instanceTotal.setOption(option2)
      }
    },
    // 产值统计
    outputChartHandle () {
      let chartData = {
        // 标段名/项目名
        names: [],
        // 合同额
        amounts: [],
        // 变更后金额
        changedAmounts: [],
        // 累计完成
        totalFinished: [],
        // 年度计划产值
        annualPlan: [],
        // 本年完成产值
        annualFinished: []
      }
      this.outputChart.instance.showLoading(this.chartLoadingText);
      this.$http.get(`/opm/home/outputChart?prjId=${this.selectedPrjId}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message({
            message: res.msg,
            type: 'error',
            duration: 3000,
          })
        }
        chartData = res.data
        this.setOutputChartOption(chartData)
        this.outputChart.instance.hideLoading()
      })
    },
    setOutputChartOption (chartData) {
      const option = {
        title: {
          // text: '产值统计'
        },
        grid: {
          x: 20,
          y: 40,
          x2: 20,
          y2: 10,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          },
          formatter (params) {
            let relVal = params[0].name;
            for (let i = 0,l = params.length; i < l; i++) {
              relVal += `<br/>${params[i].marker}${params[i].seriesName} : ${params[i].value.toLocaleString()} 万`;
            }
            return relVal;
          },
          backgroundColor: 'rgba(0,0,0,0.8)',
          borderColor: '#2196F3',
          textStyle: {
            color: '#fff'
          }
        },
        color:['#2196F3', '#00BCD4', '#4CAF50', '#FF9800', '#9C27B0'],
        legend: {
          data: ['合同金额', '变更后金额', '累计完成', '年度计划产值', '本年完成产值'],
          textStyle: {
            color: '#333'
          }
        },
        xAxis: {
          data: chartData.names,
          axisLine: {
            lineStyle: {
              color: '#999'
            }
          },
          axisLabel: {
            color: '#666',
            // rotate: 20
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} 万',
            color: '#666'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: 'rgba(0,0,0,0.1)'
            }
          }
        },
        series: [
          {
            name: '合同金额',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = -2 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#2196F3'
                    }, {
                      offset: 1,
                      color: '#1976D2'
                    }])
                  }
                }, {
                  type: 'CubeRight',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#1E88E5'
                    }, {
                      offset: 1,
                      color: '#1565C0'
                    }])
                  }
                }, {
                  type: 'CubeTop',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#42A5F5'
                    }, {
                      offset: 1,
                      color: '#2196F3'
                    }])
                  }
                }]
              }
            },
            data: chartData.amounts
          },
          {
            name: '变更后金额',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = -1 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  
                }, {
                  type: 'CubeRight',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#00ACC1'
                    }, {
                      offset: 1,
                      color: '#00838F'
                    }])
                  }
                }, {
                  type: 'CubeTop',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#26C6DA'
                    }, {
                      offset: 1,
                      color: '#00BCD4'
                    }])
                  }
                }]
              }
            },
            data: chartData.changedAmounts
          },
          {
            name: '累计完成',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = 0 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#4CAF50'
                    }, {
                      offset: 1,
                      color: '#388E3C'
                    }])
                  }
                }, {
                  type: 'CubeRight',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#43A047'
                    }, {
                      offset: 1,
                      color: '#2E7D32'
                    }])
                  }
                }, {
                  type: 'CubeTop',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#66BB6A'
                    }, {
                      offset: 1,
                      color: '#4CAF50'
                    }])
                  }
                }]
              }
            },
            data: chartData.totalFinished
          },
          {
            name: '年度计划产值',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = 1 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#FF9800'
                    }, {
                      offset: 1,
                      color: '#F57C00'
                    }])
                  }
                }, {
                  type: 'CubeRight',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#FB8C00'
                    }, {
                      offset: 1,
                      color: '#EF6C00'
                    }])
                  }
                }, {
                  type: 'CubeTop',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#FFA726'
                    }, {
                      offset: 1,
                      color: '#FF9800'
                    }])
                  }
                }]
              }
            },
            data: chartData.annualPlan
          },
          {
            name: '本年完成产值',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = 2 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#9C27B0'
                    }, {
                      offset: 1,
                      color: '#7B1FA2'
                    }])
                  }
                }, {
                  type: 'CubeRight',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#8E24AA'
                    }, {
                      offset: 1,
                      color: '#6A1B9A'
                    }])
                  }
                }, {
                  type: 'CubeTop',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                      offset: 0,
                      color: '#AB47BC'
                    }, {
                      offset: 1,
                      color: '#9C27B0'
                    }])
                  }
                }]
              }
            },
            data: chartData.annualFinished
          }
        ]
      }
      if (this.outputChart) {
        this.outputChart.instance.clear()
        this.outputChart.instance.setOption(option)
      }
    },
    // 形象进度统计
    virtualScheduleChartHandle () {
      this.virtualScheduleChart.instance.showLoading(this.chartLoadingText);
      this.$http.get(`/opm/home/virtualScheduleChart/${this.selectedPrjId}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message({
            message: res.msg,
            type: 'error',
            duration: 3000,
          })
        }
        // key：name，value：{totalNum、finishedNum、percentage}
        let dataMap = new HashMap()
        if (res.data && res.data.length > 0) {
          for (let i = 0; i < res.data.length; i++) {
            let dto = res.data[i]
            dataMap.put(dto.name, dto)
          }
        }
        this.seVirtualScheduleChartOption(dataMap)
        this.virtualScheduleChart.instance.hideLoading()
      })
    },
    seVirtualScheduleChartOption (dataMap) {
      let names = dataMap.keys()
      let percentages = []
      for (let i = 0; i < names.length; i++) {
        percentages.push(dataMap.get(names[i]).percentage)
      }
      var option = {
        // backgroundColor: '#0f1c3c',
        title: {
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          x: 40,
          y: 50,
          x2: 40,
          y2: 20,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          },
          backgroundColor: 'rgba(0,0,0,0.7)',
          borderColor: '#00ffff',
          borderWidth: 1,
          textStyle: {
            color: '#fff'
          },
          formatter (params) {
            let relVal = params[0].name
            let dto = dataMap.get(relVal)
            let _txt = (dto.finishedNum ? dto.finishedNum : 0) + '/' + dto.totalNum + '，' + dto.percentage + '%'
            for (let i = 0,l = params.length; i < l; i++) {
              relVal += `<br/>${_txt}`;
            }
            return relVal;
          }
        },
        xAxis: {
          data: names,
          axisLine: {
            lineStyle: {
              color: '#0b5263'
            }
          },
          axisLabel: {
            // color: '#4eb8cd',
            fontSize: 12
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} %',
            // color: '#4eb8cd',
            fontSize: 12
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(11,82,99,0.3)'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#0b5263'
            }
          }
        },
        series: [
          {
            name: '形象进度',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = 0 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: '#00feff'
                }, {
                  offset: 1,
                  color: '#027eff'
                }
              ])
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: color
                  }
                },
                  {
                    type: 'CubeRight',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    },
                  }]
              }
            },
            data: percentages
          },
          {
            type: 'bar',
            label: {
              show: true,
              position: 'top',
              formatter: '{c} %',
              fontSize: 14,
              // color: '#00feff',
              offset: [4, -10]
            },
            itemStyle: {
              color: 'transparent'
            },
            data: percentages
          }
        ]
      }
      // let option = {
      //   // title:{
      //   //   text:'形象进度统计',
      //   // },
      //   tooltip: {
      //     trigger: "axis",
      //     axisPointer: {
      //       type: "shadow",
      //       label: {
      //         show: true
      //       }
      //     },
      //     formatter (params) {
      //       let relVal = params[0].name
      //       let dto = dataMap.get(relVal)
      //       let _txt = (dto.finishedNum ? dto.finishedNum : 0) + '/' + dto.totalNum + '，' + dto.percentage + '%'
      //       for (let i = 0,l = params.length; i < l; i++) {
      //         relVal += `<br/>${_txt}`;
      //       }
      //       return relVal;
      //     }
      //   },
      //   grid: {
      //     left: "3%",
      //     right: "4%",
      //     top: "3%",
      //     containLabel: true,
      //   },
      //   xAxis: {
      //     type: "value",
      //     axisLine: {
      //       show: false,
      //     },
      //     axisTick: {
      //       show: false,
      //     },
      //     splitLine: { show: false },
      //     axisLabel: { show: false },
      //   },
      //   yAxis: {
      //     type: "category",
      //     data: names,
      //     splitLine: { show: false },
      //     axisLine: {
      //       show: false,
      //     },
      //     axisTick: {
      //       show: false,
      //     },
      //     offset: 10,
      //     nameTextStyle: {
      //       fontSize: 10,
      //     },
      //   },
      //   series: [
      //     {
      //       name: "形象进度",
      //       type: "bar",
      //       data: percentages,
      //       barWidth: 14,
      //       barGap: 10,
      //       smooth: true,
      //       label: {
      //         normal: {
      //           show: true,
      //           position: "right",
      //           offset: [5, -2],
      //           textStyle: {
      //             color: "#333",
      //             fontSize: 13,
      //           },
      //         },
      //       },
      //       itemStyle: {
      //         emphasis: {
      //           barBorderRadius: 7,
      //         },
      //         normal: {
      //           barBorderRadius: 7,
      //           color: this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
      //             { offset: 0, color: "#3977E6" },
      //             { offset: 1, color: "#37BBF8" },
      //           ]),
      //           label: {
      //             show: true,
      //             position: 'top',
      //             formatter: '{c} %',
      //           }
      //         },
      //       },
      //     },
      //   ],
      // }
      if (this.virtualScheduleChart) {
        this.virtualScheduleChart.instance.clear()
        this.virtualScheduleChart.instance.setOption(option)
      }
    },
    // 计量进度统计
    measScheChartHandle () {
      let chartData = {
        contractNames: [],
        periodNames: [],
        measMx: []
      }
      this.measScheChart.instance.showLoading(this.chartLoadingText);
      this.$http.get(`/mps/home/measScheCount/${this.selectedPrjId}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message({
            message: res.msg,
            type: 'error',
            duration: 3000,
          })
        }
        chartData = res.data
        this.setMeasScheChartOption(chartData)
        this.measScheChart.instance.hideLoading()
      })
    },
    // 计量进度统计图设置
    setMeasScheChartOption (chartData) {
      let series = []
      let colors = [
        new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {offset: 0, color: '#00feff'},
          {offset: 1, color: '#027eff'}
        ]),
        new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {offset: 0, color: '#ffcf00'}, 
          {offset: 1, color: '#ff5c00'}
        ]),
        new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {offset: 0, color: '#43e97b'},
          {offset: 1, color: '#38f9d7'}
        ])
      ]
      if (chartData.measMx && chartData.measMx.length > 0) {
        for (var i = 0; i < chartData.measMx.length; i++) {
          let measSche = chartData.measMx[i];
          series.push({
            name: measSche.contractName || '',
            type: 'line',
            data: measSche.latestMeasAmountTotal || [],
            symbolSize: 8,
            symbol: 'circle',
            smooth: true,
            lineStyle: {
              width: 3,
              color: colors[i % colors.length]
            },
            itemStyle: {
              color: colors[i % colors.length],
              borderWidth: 2,
              borderColor: '#fff',
              shadowColor: 'rgba(0, 0, 0, 0.3)',
              shadowBlur: 5
            },
            label: {
              show: true,
              position: 'top',
              formatter: '{c} 万',
              backgroundColor: 'rgba(0,0,0,0.6)',
              borderRadius: 4,
              padding: [4, 8],
              color: '#fff'
            },
            emphasis: {
              scale: true
            }
          })
        }
      }
      var option = {
        backgroundColor: 'transparent',
        grid: {
          x: 30,
          y: 60,
          x2: 30,
          y2: 30,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          formatter (params) {
            return `${params[0].name}：${params[0].value} 万`
          },
          backgroundColor: 'rgba(0,0,0,0.7)',
          borderColor: '#00ffff',
          borderWidth: 1,
          textStyle: {
            color: '#fff'
          }
        },
        legend: {
          data: chartData.contractNames || [],
          textStyle: {
            color: '#666'
          },
          top: 20
        },
        xAxis: {
          type: 'category',
          data: chartData.periodNames || [],
          axisLine: {
            lineStyle: {
              color: '#666'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} 万',
            color: '#666'
          },
          splitLine: {
            lineStyle: {
              type: 'dashed',
              color: '#ddd'
            }
          }
        },
        series: series
      }
      if (this.measScheChart.instance) {
        this.measScheChart.instance.clear()
        this.measScheChart.instance.setOption(option)
      }
    },
    // 质量检查统计分析
    qutyCheckChartHandle () {
      let chartData = {
        months: [],
        vals: [],
      }
      this.qutyCheckChart.instance.showLoading(this.chartLoadingText)
      this.$http.get(`/opm/home/qutyCheckChart/${this.selectedPrjId}/${this.qutyCheckChart.year}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message({
            message: res.msg,
            type: 'error',
            duration: 3000,
          })
        }
        chartData = res.data
        this.qutyCheckChartOption(chartData)
        this.qutyCheckChart.instance.hideLoading()
      })
    },
    // 质量检查统计分析-参数设置
    qutyCheckChartOption (chartData) {
      var option = {
        title: {
          // text: '质量检查问题统计'
        },
        grid: {// 控制上下左右边距
          x: 10,
          y: 40,
          x2: "20%",
          y2: 10,
          containLabel: true
        },
        tooltip: {
          trigger: 'item',
          formatter: function (p) {
            let value = p.value
            return `<div style="padding: 8px">
              ${value} 个
            </div>`
          },
          backgroundColor: 'rgba(0,0,0,0.7)',
          borderColor: '#00ffff',
          borderWidth: 1,
          textStyle: {
            color: '#fff'
          }
        },
        legend: {
          // data: ['', '']
        },
        xAxis: {
          data: chartData.months,
          axisLabel: {
            formatter: function(value) {
              if (value.startsWith('0')) {
                value = value.substring(1)
              }
              return value + '月'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}'
          }
        },
        series: [
          {
            name: '问题数量',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = 0 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: '#3B80E2'
                }, {
                  offset: 1,
                  color: '#49BEE5'
                }
              ])
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: color
                  }
                },
                  {
                    type: 'CubeRight',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    },
                  }]
              }
            },
            data: chartData.vals
          },
          {
            type: 'bar',
            label: {
              normal: {
                show: true,
                position: 'top',
                fontSize: 16,
                // color: '#fff',
                offset: [4, -10]
              }
            },
            itemStyle: {
              color: 'transparent'
            },
            data: chartData.vals,
            z: 99
          },
          // {
          //   name: '问题数量',
          //   type: 'bar',
          //   barMaxWidth: '30',
          //   data: chartData.vals,
          //   itemStyle: {
          //     normal: {
          //       // color: '#9933FF',
          //       color: this.getLinears()[0],
					// 	    borderRadius:[20, 20, 0, 0],
          //       label: {
          //         show: true,
          //         position: 'top',
          //       }
          //     }
          //   }
          // },
          {
            name: "各标段占比",
            type: "pie",
            // 半径
            radius: [60, 30],
            // 位移
            // left: 10,
            // top: -70,
            center: ['90%', '50%'], // 饼图中心位置
            data: chartData.pieChartData,
            roseType: 'radius',
            label: {
              show: true,
              position: 'inside',
              formatter: '{b}\n{d}%',
              fontSize: 12,
              color: '#666',
              fontWeight: 'bold'
            },
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 20,
                shadowColor: 'rgba(0,0,0,0.5)'
              }
            },
          }
        ]
      }
      if (this.qutyCheckChart) {
        this.qutyCheckChart.instance.clear()
        this.qutyCheckChart.instance.setOption(option)
      }
    },
    // 安全检查统计分析
    safetyCheckChartHandle () {
      let chartData = {
        months: [],
        vals: [],
      }
      this.safetyCheckChart.instance.showLoading(this.chartLoadingText)
      this.$http.get(`/opm/home/safetyCheckChart/${this.selectedPrjId}/${this.safetyCheckChart.year}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message({
            message: res.msg,
            type: 'error',
            duration: 3000,
          })
        }
        chartData = res.data
        this.safetyCheckChartOption(chartData)
        this.safetyCheckChart.instance.hideLoading()
      })
    },
    // 安全检查统计分析-参数设置
    safetyCheckChartOption (chartData) {
      var option = {
        title: {
          // text: '安全检查问题统计'
        },
        grid: {// 控制上下左右边距
          x: 20,
          y: 40,
          x2: "20%",
          y2: 10,
          containLabel: true
        },
        tooltip: {
          trigger: 'item',
          formatter: function (p) {
            let value = p.value
            return `<div style="padding: 8px">
              ${value} 个
            </div>`
          },
          backgroundColor: 'rgba(0,0,0,0.7)',
          borderColor: '#00ffff',
          borderWidth: 1,
          textStyle: {
            color: '#fff'
          }
        },
        legend: {
          // data: ['', '']
        },
        xAxis: {
          data: chartData.months,
          axisLabel: {
            formatter: function(value) {
              if (value.startsWith('0')) {
                value = value.substring(1)
              }
              return value + '月'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}'
          }
        },
        series: [
          {
            name: '问题数量',
            type: 'custom',
            renderItem: (params, api) => {
              const location = api.coord([api.value(0), api.value(1)])
              const xGap = api.coord([1, 0])[0] - api.coord([0, 0])[0]
              let xAxisPoint = api.coord([api.value(0), 0])
              let ratio = 0.15
              let xOffset = 0 * xGap * ratio
              xAxisPoint[0] = xAxisPoint[0] + xOffset
              let x = location[0] + xOffset
              let y = location[1]
              let color = new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: '#3B80E2'
              }, {
                offset: 1,
                color: '#49BEE5'
              }
              ])
              return {
                type: 'group',
                children: [{
                  type: 'CubeLeft',
                  shape: {
                    x: x,
                    y: y,
                    xAxisPoint: xAxisPoint
                  },
                  style: {
                    fill: color
                  }
                },
                  {
                    type: 'CubeRight',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    }
                  },
                  {
                    type: 'CubeTop',
                    shape: {
                      x: x,
                      y: y,
                      xAxisPoint: xAxisPoint
                    },
                    style: {
                      fill: color
                    },
                  }]
              }
            },
            data: chartData.vals
          },
          {
            type: 'bar',
            label: {
              normal: {
                show: true,
                position: 'top',
                fontSize: 16,
                // color: '#fff',
                offset: [4, -10]
              }
            },
            itemStyle: {
              color: 'transparent'
            },
            data: chartData.vals,
            z: 99
          },
          // {
          //   name: '问题数量',
          //   type: 'bar',
          //   barMaxWidth: '30',
          //   data: chartData.vals,
          //   itemStyle: {
          //     normal: {
          //       // color: '#9933FF',
          //       color: this.getLinears()[0],
					// 	    borderRadius:[20, 20, 0, 0],
          //       label: {
          //         show: true,
          //         position: 'top',
          //       }
          //     }
          //   }
          // },
          {
            name: "各标段占比",
            type: "pie",
            // 半径
            radius: [60, 30],
            // left: 300,
            // top: -70,
            center: ['90%', '50%'], // 饼图中心位置
            data: chartData.pieChartData,
            roseType: 'radius',
            label: {
              show: true,
              position: 'inside',
              formatter: '{b}\n{d}%',
              fontSize: 12,
              color: '#666',
              fontWeight: 'bold'
            },
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 20,
                shadowColor: 'rgba(0,0,0,0.5)'
              }
            },
          }
        ]
      }
      if (this.safetyCheckChart) {
        this.safetyCheckChart.instance.clear()
        this.safetyCheckChart.instance.setOption(option)
      }
    },
    // 项目地图
    mapReadyHandler ({ BMap, map }) {
      this.BMap = BMap
      this.map = map
      this.mapReady = true
      if (this.selectedPrjId) {
        this.mapHandler()
      }
    },
    // 项目地图-marker的label样式
    getMarkerLabelStyle (content) {
      //左偏移  右偏移
      var offsetSize = new this.BMap.Size(-120, -30)
      var labelStyle = {
        color: "#fff",
        backgroundColor: "rgba(255, 140, 0, 0.85)", // 改为暖橙色
        borderRadius: "15px",
        padding: "8px 15px",
        fontSize: "16px",
        lineHeight: "24px",
        border: "2px solid rgba(255,255,255,0.8)",
        boxShadow: "0 2px 6px rgba(0,0,0,0.3)",
        fontWeight: "bold",
        whiteSpace: "nowrap",
        textShadow: "1px 1px 2px rgba(0,0,0,0.5)",
        transform: "scale(0.9)",
        transition: "all 0.3s ease",
        cursor: "pointer",
        overflow: "hidden",
        position: "relative",
      }

      var label = new this.BMap.Label(content, {
        offset: offsetSize,
        enableMassClear: true
      })
      label.setStyle(labelStyle)

      // 添加鼠标悬停效果
      label.addEventListener("mouseover", function(){
        this.setStyle({
          backgroundColor: "rgba(255, 165, 0, 0.9)", // 改为较亮的橙色
          transform: "scale(1.1)",
          boxShadow: "0 4px 12px rgba(0,0,0,0.4)",
          "&:after": {
            borderTopColor: "rgba(255, 165, 0, 0.9)", // 改为较亮的橙色
            filter: "drop-shadow(0 4px 4px rgba(0,0,0,0.3))"
          }
        })
      })

      label.addEventListener("mouseout", function(){
        this.setStyle(labelStyle)
      })

      return label
    },
    // 切换项目时，更新地图标记
    mapHandler() {
      if (this.map) {
        // 清空之前地图上的所有标记
        this.map.clearOverlays()
      }
      if (this.selectedPrjId && this.BMap) {
        // let project = res.data
        let pointList = [] // 所有的点集合
        // 设置marker标记的图标
        let icon = new this.BMap.Icon(require("@/assets/img/location.gif"), new this.BMap.Size(48, 48))
        icon.setImageSize(new this.BMap.Size(48,48))
        // let projectPoint = new this.BMap.Point(project.longitude, project.latitude)
        // pointList.push(projectPoint)
        // let projectMarker = new this.BMap.Marker(projectPoint, { icon: icon })
        // // 设置marker标记的label
        // projectMarker.setLabel(this.getMarkerLabelStyle(project.name))
        // this.map.addOverlay(projectMarker)
        this.$http.get(
            '/mps/contract/openapi/briefs',
            {
              params: {
                prjId: this.selectedPrjId,
              }
            }
        ).then(({data: res}) => {
          if (res.code === 0 && res.data.length > 0) {
            for (let contract of res.data) {
              if (contract.longitude && contract.latitude) {
                let contractPoint = new this.BMap.Point(contract.longitude, contract.latitude)
                pointList.push(contractPoint)
                let contractMarker = new this.BMap.Marker(contractPoint, { icon: icon })
                contractMarker.setLabel(this.getMarkerLabelStyle(contract.name))
                this.map.addOverlay(contractMarker)
              }
            }
            // 计算所有点的中心点，将地图聚焦到中心点，并设置地图缩放级别
            let centerPoint = this.map.getViewport(pointList)
            this.map.centerAndZoom(centerPoint.center, centerPoint.zoom * 0.9)
          }
        })
      }
    },
    // 根据当前年份动态生成最近五年的年份选项
    makeYearOptions() {
      let year = new Date().getFullYear()
      let arr = []
      for (let i = 0; i < 4; i++) {
        arr.push(year - i)
      }
      arr.sort()
      return arr;
    },
    // 预设十个渐变色，统计图可直接调用该方法获取颜色
    getLinears() {
      let linears = []

      // linears.push({
      //   type: 'linear',
      //   x: 0,
      //   y: 0,
      //   x2: 0,
      //   y2:1,
      //   colorStops: [{
      //     offset: 0, color: '#02bcff'
      //   }, {
      //     offset: 1, color: '#0081ff' // 海蓝
      //   }],
      //   global: false
      // })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#00FF00'
        }, {
          offset: 1, color: '#7FFF00' // 亮绿
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFB6C1'
        }, {
          offset: 1, color: '#FF69B4' // 桃粉
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFCC99'
        }, {
          offset: 1, color: '#fbbd08' // 明黄
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFCCFF'
        }, {
          offset: 1, color: '#e54d42' // 嫣红
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#00CCFF'
        }, {
          offset: 1, color: '#00CCFF' // 亮蓝
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#99FFFF'
        }, {
          offset: 1, color: '#00FFFF' // 橄榄
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFDDAA'
        }, {
          offset: 1, color: '#EE7700' // 棕褐
        }],
      })

      linears.push({
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFCCFF'
        }, {
          offset: 1, color: '#FF00FF' // 粉色
        }],
      })

      return linears
    },
    // echarts注册自定义图形
    echartsRegisterCustomShape() {
      const CubeLeft = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function(ctx, shape) {
          const xAxisPoint = shape.xAxisPoint
          const c0 = [shape.x, shape.y]
          const c1 = [shape.x - 9, shape.y - 9]
          const c2 = [xAxisPoint[0] - 9, xAxisPoint[1] - 9]
          const c3 = [xAxisPoint[0], xAxisPoint[1]]
          ctx.moveTo(c0[0], c0[1]).lineTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1])
              .closePath()
        }
      })
      const CubeRight = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function(ctx, shape) {
          const xAxisPoint = shape.xAxisPoint
          const c1 = [shape.x, shape.y]
          const c2 = [xAxisPoint[0], xAxisPoint[1]]
          const c3 = [xAxisPoint[0] + 18, xAxisPoint[1] - 9]
          const c4 = [shape.x + 18, shape.y - 9]
          ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1])
              .closePath()
        }
      })
      const CubeTop = this.$echarts.graphic.extendShape({
        shape: {
          x: 0,
          y: 0
        },
        buildPath: function(ctx, shape) {
          if (shape.y - shape.xAxisPoint[1] == 0) {
            return
          }
          const c1 = [shape.x, shape.y]
          const c2 = [shape.x + 18, shape.y - 9]
          const c3 = [shape.x + 9, shape.y - 18]
          const c4 = [shape.x - 9, shape.y - 9]
          ctx.moveTo(c1[0], c1[1]).lineTo(c2[0], c2[1]).lineTo(c3[0], c3[1]).lineTo(c4[0], c4[1])
              .closePath()
        }
      })
      this.$echarts.graphic.registerShape('CubeLeft', CubeLeft)
      this.$echarts.graphic.registerShape('CubeRight', CubeRight)
      this.$echarts.graphic.registerShape('CubeTop', CubeTop)
    }
  }
}
</script>
<style scoped>
  ::v-deep .el-card {
    cursor: pointer;
  }

  ::v-deep .el-card .el-card__header {
    padding: 0;
    height: 30px;
    color: white;
  }

  ::v-deep .el-card__body {
    padding: 1px !important;
  }

  ::v-deep .header-default {
    background: #409EFF;
    padding: 5px;
  }

  ::v-deep .header-blue {
    background: #409EFF;
    padding: 5px;
  }

  ::v-deep .header-green {
    background: #67C23A;
    padding: 5px;
  }

  ::v-deep .header-brown {
    background: #E6A23C;
    padding: 5px;
  }

  ::v-deep .header-red {
    background: #F56C6C;
    padding: 5px;
  }

  ::v-deep .scrollbar-card {
    height: 200px;
  }

  ::v-deep .span-danger {
    font-weight: bold;
    font-size: 16px;
    color: red;
  }

  ::v-deep .span-warn {
    font-weight: bold;
    font-size: 16px;
    color: #E6A23C;
  }

  ul {
    padding: 0;
    margin: 0;
    text-align: left;
    list-style-type: none;
  }

  li {
    list-style-type: none;
    padding-top: 5px;
  }

  .grid-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100px;
  }

  .grid-con-icon-bg-blue {
    background: #2d8cf0;
  }

  .grid-con-icon-bg-green {
    background: #64d572;
  }

  .grid-con-icon-bg-orange {
    background: #f25e43;
  }

  .grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
  }

  .grid-cont-right {
    padding-left: 5px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    text-align: center;
    font-size: 16px;
    color: #999;
  }
  .myMapAngle {
    display: inline-block;
    width: 0px;
    height: 0px;
    position: absolute;
    bottom: -60px;
    border: 14px solid;
    left: 15px;
    bottom: -25px;
    opacity: 0.8;
    border-color: #333333 transparent transparent transparent;
  }
  
  .color-box-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1px 2px 4px 2px;
    color: white; 
    font-size: 15px;
    height: 62px;
    border-radius: 8px;
  }

  .color-box-2 {
    height: 132px;
    padding: 6px;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
  }
</style>