<template>
  <div class="video-container">
    <video ref="videoRef" autoplay loop :muted="true">
      <source :src="videoSource" type="video/mp4">
    </video>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoSource: 'https://plugins.pmbim.com.cn/demo/video/nanjing1.mp4'
    }
  },
  mounted() {
    this.$refs.videoRef.addEventListener('ended', this.restartVideo())
  },
  methods: {
    methods: {
        restartVideo() {
            this.$refs.videoRef.currentTime = 0;
            this.$refs.videoRef.play();
        },
    }
  }
}
</script>
<style lang="scss" scoped>
.video-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>